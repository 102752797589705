<template>
  <!-- 文章详情 -->
  <Layout>
    <div class="main-article">
      <div class="md-article-1" v-ani.fade-up>
        <div class="base-width flex-middle">
          <!-- 面包屑 -->
          <BreadCrumb :list='routes' />
          <!-- 返回列表 -->
          <router-link class="back color-blue s16 font-light flex-middle" to="/news">
            <span>返回列表</span>
            <img src="@/assets/images/icon-back.png" alt="">
          </router-link>
        </div>
      </div>
      <div class="md-article-2">
        <div class="base-width">
          <!-- 中间 -->
          <div class="main">
            <!-- 详情 -->
            <div class="details c-card" v-ani.fade-up>
              <h1 class="title s26 font-medium text-center">{{ info.title }}</h1>
              <div class="data s16 color-9 flex-center">
                <span class="item">#{{ info.topic }}#</span>
                <span class="item">发布日期：{{ info.createTime | dateFormat('yyyy/MM/dd HH:mm') }}</span>
              </div>
              <div class="editor s16 color-4">
                <p>根据《关于公布国家职业资格目录的通知》 (人社部发[2017) 68号)、《关于深化工程技术人才职称制度改革的指导意见》 (人社部发 [2019] 16号)、《关于印发经济专业技术资格规定和经济专业技术资格考试实施办法的通知》(人社部规[2020] 1号) 以及国家各项专业技术人员职业资格制度规定，对应关系如下:</p>
                <p><br></p>
                <p>工程技术领域:注册消防工程师、注册建筑师、造价工程师、建造师、注册结构工程师、注册计量师、注册安全工程师、通信专业技术人员资格、机动车检测维修专业技术人员职业资格、公路水运工程试验检测专业技术人员职业资格、质量专业技术人员职业资格，以上各项专业技术人员职业资格，其初级 (二级)、中级 (一级) 资格分别对应我省工程技术人才系列的助理工程师、工程师职称。</p>
                <p><br></p>
                <p>根据《关于公布国家职业资格目录的通知》 (人社部发[2017) 68号)、《关于深化工程技术人才职称制度改革的指导意见》 (人社部发 [2019] 16号)、《关于印发经济专业技术资格规定和经济专业技术资格考试实施办法的通知》(人社部规[2020] 1号) 以及国家各项专业技术人员职业资格制度规定，对应关系如下:</p>
                <p><br></p>
                <p>工程技术领域:注册消防工程师、注册建筑师、造价工程师、建造师、注册结构工程师、注册计量师、注册安全工程师、通信专业技术人员资格、机动车检测维修专业技术人员职业资格、公路水运工程试验检测专业技术人员职业资格、质量专业技术人员职业资格，以上各项专业技术人员职业资格，其初级 (二级)、中级 (一级) 资格分别对应我省工程技术人才系列的助理工程师、工程师职称。</p>
                <p><br></p>
                <p>
                  <img src="@/assets/images/svc_13.png" alt="">
                </p>
                <p><br></p>
                <p>根据《关于公布国家职业资格目录的通知》 (人社部发[2017) 68号)、《关于深化工程技术人才职称制度改革的指导意见》 (人社部发 [2019] 16号)、《关于印发经济专业技术资格规定和经济专业技术资格考试实施办法的通知》(人社部规[2020] 1号) 以及国家各项专业技术人员职业资格制度规定，对应关系如下:</p>
                <p><br></p>
                <p>工程技术领域:注册消防工程师、注册建筑师、造价工程师、建造师、注册结构工程师、注册计量师、注册安全工程师、通信专业技术人员资格、机动车检测维修专业技术人员职业资格、公路水运工程试验检测专业技术人员职业资格、质量专业技术人员职业资格，以上各项专业技术人员职业资格，其初级 (二级)、中级 (一级) 资格分别对应我省工程技术人才系列的助理工程师、工程师职称。</p>
              </div>
              <div class="page s16 color-2 font-medium">
                <div class="page-item">
                  <router-link to="">
                    上一页：模板工施工实训
                  </router-link>
                </div>
                <div class="page-item">
                  <router-link to="">
                    下一页：模板工施工实训
                  </router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>
export default {
  data() {
    return {
      info: {
        title: '模板工施工实训',
        topic: '模板工施工实训',
        createTime: '2023/01/01 16:11:00'
      },
      list: [
        {
          id: 1,
          img: require('@/assets/images/avatar3.png'),
          name: '今照千心',
          time: '3小时前',
          content: '广州科学馆是继广州塔、中信广场、天河体育中心、广州市文化馆新馆等标志性工程'          
        }, {
          id: 2,
          img: require('@/assets/images/avatar3.png'),
          name: '李四先生',
          time: '3小时前',
          content: '为实现广州科学馆整体建设目标打下坚实基础'          
        }, 
      ],
      isLogin: false,
      routes: [
        {
          name: '首页',
          link: '/'
        }, {
          name: '配套服务',
          link: '/service'
        }, {
          name: '辅助工具',
          link: '/service/tools'
        }, {
          name: '施工指导教程详情',
          link: ''
        }
      ]
    };
  },
  methods: {
    handleSubmit() {}
  }
};
</script>

<style lang="scss" scoped>
.md-article-1{
  padding: 0.26rem 0 .21rem;
  .base-width{
    justify-content: space-between;
  }
  .back{
    img{
      width: 0.11rem; margin-left: .04rem;
    }
    &:hover{
      text-decoration: underline;
      font-weight: bold;
    }
  }
}
.md-article-2{
  padding-bottom: .4rem;
  .main{
    width: 12.32rem;
    margin-left: auto; margin-right: auto;
  }
}
.details{
  padding: .56rem .4rem;
  .title{}
  .data{
    margin-top: .15rem;
    .item{
      margin-right: .25rem;
      &:last-of-type{
        margin-right: 0;
      }
    }
  }
  .editor{
    border-top: 1px solid #e6f2f8;
    margin-top: .17rem;
    line-height: calc(30 / 16 * 1em);
    padding-top: .22rem; padding-bottom: .33rem;
  }
  .page{
    border-top: 1px solid #e6f2f8;
    padding-top: .36rem;
    .page-item{
      margin-bottom: .19rem;
      &:last-of-type{
        margin-bottom: 0;
      }
    }
    a{
      &:hover{
        color: $blue;
      }
    }
  }
}
</style>